(function ($, generic, site) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if ($emailContainerNodes.length < 1) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form')
          ? $emailContainerNode
          : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $smsSuccess = $('.js-email-signup__sms_success', $emailContainerNode);
        var $contentErrors = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $firstName = $('.js-email-first-name', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var $emailError = $(
          '.site-email-signup__message__error__email_address',
          $emailContainerNode
        );
        var $phoneError = $(
          '.site-email-signup__message__error__mobile_phone',
          $emailContainerNode
        );
        var $genericError = $('.site-email-signup__message__error__generic', $emailContainerNode);
        var $smsError = $('.js-site-email-signup-message-error-terms', $emailContainerNode);
        var $emailTermsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);
        var $emailTermsError = $('.js-email-terms-conditions-error', $emailContainerNode);
        var $emailTermsLabel = $('.js-site-email-signup-terms-checkbox', $emailContainerNode);
        var $emailcheckbox = $('.js-email-terms-checkbox', $emailContainerNode);
        var showEmailPromo = $emailContainerNode.data('show-email-promotion');

        // Show terms + conditions for mobile only
        $mobileNumberInput.on('focus', function () {
          $termsAndConditions.slideDown(300);
        });

        if (showEmailPromo) {
          $emailInput.on('focus', function () {
            $emailTermsAndConditions.slideDown(300);
          });
        }

        $emailForm.once('js-email-signup').on('submit', function (submitEvt) {
          // Transform string into array of form elements
          var params = {};

          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $smsSuccess.add($phoneError).addClass('hidden');
          $smsSuccess.add($smsError).addClass('hidden');
          $emailInput.removeClass('error');
          $mobileNumberInput.removeClass('error');
          $termsAndConditions.removeClass('error');
          $emailError.addClass('hidden');
          $phoneError.addClass('hidden');
          $smsError.addClass('hidden');
          $genericError.addClass('hidden');
          $emailError.removeClass('error');
          $phoneError.removeClass('error');
          $genericError.removeClass('error');
          $smsError.removeClass('error');

          $emailForm.serializeArray().forEach(function (kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          /* Handle the sms_promotions checkbox
           If its checked its being read as 'on' when serialized
           We need a boolean value */
          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          } else {
            params.SMS_PROMOTIONS = 0;
          }

          if (showEmailPromo) {
            $emailTermsError.addClass('hidden');
            $emailTermsError.removeClass('error');
            $emailTermsLabel.removeClass('error');
            params.PC_EMAIL_PROMOTIONS = $emailcheckbox.prop('checked') ? 1 : 0;
          }

          if (!$emailInput.val().length) {
             $contentErrors.removeClass('hidden');
             $emailInput.addClass('error');
             $genericError.removeClass('hidden').addClass('error');
             generic.focusErrors($contentErrors, $('.js-email-signup'));
             return false;
          }

          if (showEmailPromo && !$emailcheckbox.prop('checked')) {
            $contentErrors.removeClass('hidden');
            $emailTermsError.removeClass('hidden').addClass('error');
            generic.focusErrors($contentErrors, site.emailSignup.templateContainer);

            return false;
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var $successContainer = $emailSuccess;

              if (response.userinfo === undefined) {
                $successContainer = $smsSuccess;
              }
              if ($successContainer.hasClass('inline')) {
                $successContainer.removeClass('hidden');
              } else {
                $emailInput.val('');
                $firstName.val('');
                $mobileNumberInput.val('');
                $termsAndConditions.slideUp(0);
                if (showEmailPromo) {
                  $emailInput.val('');
                  $emailTermsAndConditions.slideUp(0);
                }
                generic.overlay.launch({
                  height: 'auto',
                  html: $successContainer.html()
                });
              }
            },
            onFailure: function (jsonRpcResponse) {
              var genericMessage = false;
              var emailMessage = false;
              var mobileMessage = false;
              var smsMessage = false;
              var errObjs = jsonRpcResponse.getMessages();
              var i;
              var j;
              var myErr;

              for (i = 0; i < errObjs.length; i++) {
                myErr = errObjs[i];

                for (j = 0; j < myErr.tags.length; j++) {
                  if (myErr.tags[j] === 'field.pc_email_address') {
                    emailMessage = true;
                  } else if (myErr.tags[j] === 'field.mobile_phone') {
                    mobileMessage = true;
                  } else if (myErr.tags[j] === 'field.sms_promotions') {
                    smsMessage = true;
                  }
                }
              }
              if (mobileMessage === true && emailMessage === true) {
                genericMessage = true;
              }
              $contentErrors.removeClass('hidden');
              if (genericMessage === false && emailMessage === true) {
                $emailInput.addClass('error');
                $emailError.removeClass('hidden');
                $emailError.addClass('error');
              } else if (genericMessage === false && mobileMessage === true) {
                $mobileNumberInput.addClass('error');
                $phoneError.removeClass('hidden');
                $phoneError.addClass('error');
              }
              if (genericMessage === false && smsMessage === true) {
                $termsAndConditions.addClass('error');
                $smsError.removeClass('hidden');
                $smsError.addClass('error');
              }
              if (genericMessage === true) {
                $('.js-subscribe-signup').addClass('error');
                $emailInput.addClass('error');
                $mobileNumberInput.addClass('error');
                $genericError.removeClass('hidden');
                $genericError.addClass('error');
                for (i = 0; i < errObjs.length; i++) {
                  myErr = errObjs[i];

                  if (showEmailPromo) {
                    if (
                      myErr.key ===
                      'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup'
                    ) {
                      $emailTermsLabel.addClass('error');
                      $emailTermsError.removeClass('hidden');
                      $genericError.addClass('hidden');
                      $genericError.removeClass('error');
                      $emailInput.removeClass('error');
                    }
                  }
                }
              }
              generic.focusErrors($('.js-email-signup__error'), $('.js-email-signup'));
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.emailSmsSignupV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
    }
  };
})(jQuery, window.generic || {}, window.site || {});
